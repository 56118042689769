var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"crud-content",attrs:{"fill-height":""}},[_c('ListLayout',{attrs:{"title":_vm.title,"info":_vm.info,"buttonActions":_vm.buttonActions},scopedSlots:_vm._u([{key:"filter",fn:function(){return [_c('filter-container',{scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('topic-form-filter',{on:{"onSubmit":_vm.onSubmitFilterHandler,"onReset":_vm.onResetFilterHandler}})]},proxy:true}])})]},proxy:true},{key:"content",fn:function(){return [_c('v-data-table',{staticClass:"elevation-1 custom-table",attrs:{"headers":_vm.header,"loading":_vm.loading,"items":_vm.content,"options":_vm.paginationOptions,"server-items-length":_vm.totalElements,"footer-props":{
          'items-length': 100,
          'items-per-page-text': 'Linhas por página',
          'items-per-page-options': [10, 20, 50],
          pageText: '{0}-{1} de {2}',
        }},on:{"update:options":function($event){_vm.paginationOptions=$event}},scopedSlots:_vm._u([{key:"item.orderInfoRequired",fn:function(ref){
        var item = ref.item;
return [(item.orderInfoRequired)?_c('v-icon',{attrs:{"color":"var(--green-0)"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"var(--red-0)"}},[_vm._v("mdi-close")])]}},{key:"item.sellerAuthRequired",fn:function(ref){
        var item = ref.item;
return [(item.sellerAuthRequired)?_c('v-icon',{attrs:{"color":"var(--green-0)"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"var(--red-0)"}},[_vm._v("mdi-close")])]}},{key:"item.koerichAdminAuthRequired",fn:function(ref){
        var item = ref.item;
return [(item.koerichAdminAuthRequired)?_c('v-icon',{attrs:{"color":"var(--green-0)"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"var(--red-0)"}},[_vm._v("mdi-close")])]}},{key:"item.cancelOrderEnabled",fn:function(ref){
        var item = ref.item;
return [(item.cancelOrderEnabled)?_c('v-icon',{attrs:{"color":"var(--green-0)"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"var(--red-0)"}},[_vm._v("mdi-close")])]}},{key:"item.enabled",fn:function(ref){
        var item = ref.item;
return [(item.enabled)?_c('div',{staticClass:"justify-center color--green-0"},[_vm._v("Ativo")]):_c('div',{staticClass:"justify-center color--red-0"},[_vm._v("Inativo")])]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDetail(item.id)}}},[_vm._v("mdi-pencil")])]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"pt-10 pb-10"},[_vm._v(" Nenhum resultado encontrado ")])]},proxy:true}],null,true)})]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }