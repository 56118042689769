<template>
  <v-container class='crud-content' fill-height>
    <ListLayout :title="title" :info="info"  :buttonActions="buttonActions">
      <template v-slot:filter>
        <filter-container>
          <template v-slot:content>
            <topic-form-filter @onSubmit="onSubmitFilterHandler" @onReset="onResetFilterHandler" />
          </template>
        </filter-container>
      </template>
      <template v-slot:content>
        <v-data-table
          :headers="header"
          :loading="loading"
          :items="content"
          class="elevation-1 custom-table"
          :options.sync="paginationOptions"
          :server-items-length="totalElements"
          :footer-props="{
            'items-length': 100,
            'items-per-page-text': 'Linhas por página',
            'items-per-page-options': [10, 20, 50],
            pageText: '{0}-{1} de {2}',
          }"
        >
        <template v-slot:[`item.orderInfoRequired`]="{ item }">
            <v-icon v-if="item.orderInfoRequired" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.sellerAuthRequired`]="{ item }">
            <v-icon v-if="item.sellerAuthRequired" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.koerichAdminAuthRequired`]="{ item }">
            <v-icon v-if="item.koerichAdminAuthRequired" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.cancelOrderEnabled`]="{ item }">
            <v-icon v-if="item.cancelOrderEnabled" color="var(--green-0)">mdi-check</v-icon>
            <v-icon v-else color="var(--red-0)">mdi-close</v-icon>
          </template>
          <template v-slot:[`item.enabled`]="{ item }">
            <div v-if="item.enabled" class="justify-center color--green-0">Ativo</div>
            <div v-else class="justify-center color--red-0">Inativo</div>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
              <v-icon color="primary" @click="openDetail(item.id)">mdi-pencil</v-icon>
          </template>
          <template v-slot:no-data>
            <div class="pt-10 pb-10">
              Nenhum resultado encontrado
            </div>
          </template>
        </v-data-table>
      </template>
    </ListLayout>
  </v-container>
</template>

<script>
import ListLayout from '@/components/template/list/ListLayout';
import FilterContainer from '@/components/filter-layout/FilterContainer';
import dataCrudMixin from '../../components/template/crud/mixins/dataCrudMixin';
import isAdmin from '@/utils/user-utils';
import notification from '@/service/notification';
import { routerPush } from '@/service/kplaceRouter';
import { listTopic } from '@/service/topics/topics-service';
import TopicFormFilter from './filter/TopicFormFilter';

export default {
  // name: 'List',
  components: {
    ListLayout,
    TopicFormFilter,
    FilterContainer,
  },
  mixins: [dataCrudMixin],
  data() {
    return {
      isAdmin: isAdmin(),
      title: 'Assuntos para atendimento',
      loading: false,
      info: '',
      paginationOptions: {},
      formDataFilter: {},
      buttonActions: isAdmin() ? [{ name: 'CADASTRAR ASSUNTO', action: this.newTopic }] : [],
      header: [
        { text: 'Assunto', sortable: true, value: 'title' },
        { text: 'Requer nº pedido', sortable: true, value: 'orderInfoRequired', align: 'center' },
        { text: 'Requer aut. seller', sortable: true, value: 'sellerAuthRequired', align: 'center' },
        { text: 'Requer aut. adm.', sortable: true, value: 'koerichAdminAuthRequired', align: 'center' },
        { text: 'Cancelamento de pedido', sortable: true, value: 'cancelOrderEnabled', align: 'center' },
        { text: 'Status', sortable: true, value: 'enabled', align: 'center' },
        { text: 'Ação', sortable: false, value: 'actions', align: 'center' },
      ],
    };
  },
  watch: {
    paginationOptions: {
      handler() {
        this.init();
      },
    },
  },
  methods: {
    init() {
      this.getTopicListHandler(this.formDataFilter);
    },
    async getTopicListHandler(formDataFilter) {
      try {
        this.loading = true;
        const requestReponse = await listTopic(formDataFilter, this.paginationOptions);
        if (requestReponse.status >= 200 && requestReponse.status <= 300) {
          this.content = requestReponse.data.register;
          this.totalElements = requestReponse.data.totalRegister;
        } else {
          notification(`${requestReponse.data.message}`, 'error');
        }
      } catch (ex) {
        notification(`Erro ao realizar busca de assuntos: ${ex}`, 'error');
      } finally {
        this.loading = false;
      }
    },
    newTopic() {
      routerPush('/topic/forms');
    },
    openDetail(id) {
      routerPush(`/topic/forms/edit/${id}`);
    },
    onSubmitFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getTopicListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
    onResetFilterHandler(formDataFilter) {
      this.formDataFilter = formDataFilter;
      this.getTopicListHandler(this.formDataFilter);
      this.paginationOptions.page = 1;
    },
  },
  async mounted() {
     if (this.paginationOptions.sortBy.length === 0) {
      this.paginationOptions.sortBy.push('title');
     this.paginationOptions.sortDesc.push(true);
    }
    await this.init();
  },
};
</script>

<style lang="scss" scoped>
.crud-content {
  background-color: #f8f8f8;
  height: calc(100% - 160px);
  width: 100%;
  margin-top: 126px;
  border-radius: 15px;
  padding: 20px 20px 20px 20px;
}
</style>
