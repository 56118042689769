<template>
  <v-form ref="filterForm" lazy-validation @submit="onSubmitHandler">
    <content-base-layout>
      <template v-slot:content>
        <div class="form-content">

          <div class="col-4">
            <label class="filter-label">Assuntos</label>
            <v-text-field
            v-model="form.title"
            placeholder="Digite"
            dense
            filled
            prepend-inner-icon="mdi-magnify" />
          </div>

          <div class="col-3">
            <label class="filter-label">Status</label>
            <v-autocomplete
              class="data_filter_select_input"
              :no-filter="true"
              item-text="label"
              item-value="value"
              v-model="form.enabled"
              :items="statusOptions"
              placeholder="Todos"
              filled
              dense
            />
          </div>
        </div>
      </template>

      <template v-slot:actions>
        <filter-base-actions @onReset="onClearHandler" />
      </template>
    </content-base-layout>
  </v-form>
</template>

<script>
import ContentBaseLayout from '@/components/filter-layout/ContentBaseLayout';
import FilterBaseActions from '@/components/filter-layout/FilterBaseActions';

const FilterFields = {
  title: '',
  enabled: '',
};

export default {
  components: { ContentBaseLayout, FilterBaseActions },
  data() {
    return {
      form: { ...FilterFields },
      statusOptions: [
          {
            label: 'Ativo',
            value: 'true',
          },
          {
            label: 'Inativo',
            value: 'false',
          },
        ],
    };
  },
  methods: {
    onSubmitHandler(e) {
      e.preventDefault();
      const { filterForm } = this.$refs;
      const validated = filterForm?.validate();

      if (!validated) return;

      const formData = { ...this.form };
      this.$emit('onSubmit', formData);
    },
    onClearHandler() {
      this.form = { ...this.form, ...FilterFields };
      this.$emit('onReset', this.form);
    },
  },
};
</script>
